.collection_con{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f9f9f9;
    .collection_head{
        height: 100px;
        display: flex;
        align-items: center;
        padding:0 24px;
        .collection_logo{
            cursor: pointer;
            width: 120px;
            display: flex;
            align-items: center;
            font-size: 18px;
            margin-right: 54px;
            img{
                width: 40px;
                margin-right: 4px;
            }
        }
        > ul{
            position: absolute;
            padding-top: 4px;
            top: 10px;
            left: 180px;
            width: calc(100% - 180px);
            overflow: scroll;
            height: 200px;
            // 
            display: flex;
            li{
                display: flex;
                flex-direction: column;
                min-width: 120px;
                justify-content: center;
                align-items: center;

                padding: 14px;
                height: 70px;
                border-radius: 12px;
                box-sizing: border-box;
                background-color: #fff;
                margin-right: 14px;
                cursor: pointer;
                line-height: 22px;
                transition: all .3s ease;
                position: relative;
                z-index: 1000;
                img{
                    width: 40px;
                    border-radius: 50%;
                    // margin-right: 8px;
                }
                p{
                    flex:1;
                    font-size: 14px;
                    font-weight: bold;
                    span{
                        font-weight: 400;
                        max-width: 120px;
                        display: block;
                        white-space:nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 12px;
                    }
                }
                // 气泡框
                .bubble-box{
                    position: absolute;
                    top:70px;
                    left:0;
                    display: none;
                    .bubble-container {
                        position: relative;
                        background-color: #191919; /* 气泡框背景颜色 */
                        color: #fff; /* 文字颜色 */
                        border-radius: 10px; /* 圆角边框 */
                        padding: 10px; /* 内边距 */
                        margin: 10px; /* 外边距，可根据需要调整 */
                        z-index: 1000;
                        span{
                            font-size: 12px;
                        }
                        .triangle {
                            position: absolute;
                            width: 0;
                            height: 0;
                            border-left: 10px solid transparent; /* 左边透明 */
                            border-right: 10px solid transparent; /* 右边透明 */
                            border-bottom: 10px solid #191919; /* 底边为气泡框背景色 */
                            top: -8px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
            li:hover {
                margin-top:-3px;
                box-shadow: 0 26px 40px -24px rgba(0,36,100,.3);
                .bubble-box{
                    display: block;
                }
                p{
                    color:#4449b4;
                }
            }
        }
        > ul::-webkit-scrollbar {
            height: 0;
        }
    }
    .collection_body{
        flex:1;
        display: flex;
        .collection_cho{
            width: 200px;
            overflow: scroll;
            padding-left: 12px;
            .collection_cho_item{
                cursor: pointer;
                width: 150px;
                display: flex;
                align-items: center;
                // background-color: #fff;
                margin-bottom: 16px;
                border-radius: 12px;
                padding: 6px 14px;
                img{
                    margin-right: 12px;
                    width: 34px;
                    border-radius: 50%;
                }
            }
            .collection_cho_item:last-child{
                margin-bottom: 0;
            }
        }
        iframe {
            position: fixed;
            // background: #000;
            border: none;
            top: 100px; 
            right: 0;
            bottom: 0; 
            left: 200px;
            width: calc(100% - 200px);
            height: calc(100% - 100px);
        }
    }
}

.slider_box{
    // max-width: 1400px;
    .slick-track{
        margin:0;
    }
    .slider_chi{
        cursor: pointer;
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 64px;
        border-radius: 12px;
        background-color: #fff;
        box-sizing: border-box;
        padding-left: 12px;
        // padding-top:6px;
        position: relative;
        .fun_box{
            position: absolute;
            top:0;
            right: 0;
            font-size: 12px;
            padding: 2px 8px;
            background: linear-gradient(270deg,rgba(198,240,230,.16),rgba(198,240,230,.4));
            color: #25ab46;
            border-radius: 0 0 0 12px;
        }
        img{
            width: 34px;
            border-radius: 50%;
            margin-right: 8px;
        }
        p{
            flex:1;
            font-size: 14px;
            font-weight: bold;
        }
    }
    .spe_box{
        background-color: rgb(235, 235, 235);
        color: grey;
    }
}
.slick-prev:before, .slick-next:before{
    color: #436ff6!important;
    font-size: 24px!important;
}
@primary-color: #1DA57A;